<mat-sidenav-container autosize [hasBackdrop]="false">

  <mat-sidenav #leftSidenav [mode]="isMobile ? 'over' : 'side'" [opened]="!isMobile"
    [ngClass]="navbarMini? 'mini-size': 'normal-size'">
    <div class="flex-nav-container">

      <mat-nav-list>
        <mat-accordion>
          @for (menu of menus; track menu) {
          <a mat-list-item [routerLink]="menu.link" routerLinkActive="active-list-item" #nav="routerLinkActive"
            (click)="isMobile ? leftSidenav.close() : false">
            <mat-icon matTooltip="{{ navbarMini ? (menu.label | translate) : '' }}" matTooltipPosition="right" class="nav"
              [color]="nav.isActive?'accent' : ''">
              {{ menu.icon }}
            </mat-icon>{{ menu.label | translate }}
          </a>
          }
        </mat-accordion>
      </mat-nav-list>

      <mat-nav-list>
        <mat-divider></mat-divider>
        <a mat-list-item [routerLink]="'settings'" routerLinkActive="active-list-item" #set="routerLinkActive"
          (click)="isMobile ? leftSidenav.close() : false">
          <div class="settings">
            <div>
              <mat-icon [matTooltip]="navbarMini ? ('menu.settings' | translate) : ''"  matTooltipPosition="right" class="nav"
                [color]="set.isActive ? 'accent' : ''">
                settings
              </mat-icon>

              {{ 'menu.settings' | translate }}
            </div>
            @if (!navbarMini) {
            <mat-icon [matTooltip]="navbarMini ? 'Expand menu' : 'Collapse menu'" (click)="sidenavMinimize($event)">
              {{ navbarMini ? 'arrow_forward_ios' : 'arrow_back_ios' }}
            </mat-icon>
            }
          </div>
        </a>

        @if (navbarMini) {
        <a mat-list-item (click)="sidenavMinimize($event)" href="">
          <mat-icon [matTooltip]="navbarMini?'Expand menu':'Collapse menu'">
            {{ navbarMini ? 'arrow_forward_ios' : 'arrow_back_ios' }}
          </mat-icon>
        </a>
        }
      </mat-nav-list>

    </div>
  </mat-sidenav>

  <mat-sidenav #rightSidenav position="end" mode="over" class="right-sidenav">
    <div class="right-close-placeholder">
      <button mat-icon-button (click)="rightSidenav.close()">
        <mat-icon color="accent">navigate_next</mat-icon>
      </button>
      <div>{{ rightComponent?.label||'' | translate }}</div>
    </div>
    <mat-divider></mat-divider>
    @switch (rightComponent?.link) {
    @case ('help') {
    <app-help></app-help>
    }
    @case ('notification') {
    <app-notification></app-notification>
    }
    @case ('account') {
    <app-account></app-account>
    }
    }
  </mat-sidenav>

  <mat-sidenav-content>
    @if (busy$ | async) {
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <div class="outlet-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>