import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../services/notification.service';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
    providedIn: 'root'
})
export class SupervisorGuard {
    constructor(
        private readonly notificationService: NotificationService,
        private readonly translate: TranslateService,
        private readonly authService: AuthService,
    ) { }

    canActivate(): Observable<boolean> {
        return this.authService.isSupervisor().pipe(
            tap(p => p || this.notificationService.errorMsg(this.translate.instant('route.noPermission')))
        );
    }
}
