import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationItems } from '../../../app-core/models/navigation-item';
import { MatCommonModule } from '@angular/material/core';
import { NotificationComponent } from '../../../app-core/ui/sidenav/notification/notification.component';
import { HelpComponent } from '../../../app-core/ui/sidenav/help/help.component';
import { AccountComponent } from '../../../app-core/ui/sidenav/account/account.component';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NotificationService } from '../../../app-core/services/notification.service';
import { MenuButton } from '../../models/menu-button.model';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [
    HelpComponent,
    NotificationComponent,
    AccountComponent,
    MatCommonModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatButtonModule,
    TranslateModule,
    MatExpansionModule,
    CommonModule,
  ],
  providers: [
  ]
})
export class SidenavComponent {
  @ViewChild('leftSidenav') leftSidenav!: MatSidenav;
  @ViewChild('rightSidenav') rightSidenav!: MatSidenav;
  public rightComponent?: MenuButton;
  public navbarMini: boolean;
  public menus: MenuButton[] = NavigationItems;
  public busy$: Observable<boolean>;
  public isMobile = false;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {
    this.busy$ = this.notificationService.busy$;
    this.navbarMini = JSON.parse(localStorage.getItem('menuMinimized') || true.toString());

    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(
      (result: BreakpointState) => this.isMobile = result.matches
    );
  }


  sidenavMinimize(event: MouseEvent): void {
    this.navbarMini = !this.navbarMini;
    event.stopPropagation();
    event.preventDefault();
    localStorage.setItem('menuMinimized', this.navbarMini.toString());
  }


  toggleRightMenu(component: MenuButton): void {
    if (this.isMobile) {
      this.router.navigate([component.link]);
      return
    }
    if (this.rightComponent === component && this.rightSidenav.opened) {
      this.rightSidenav.close();
      this.rightComponent = undefined;
      return;
    }
    this.rightComponent = component;
    this.rightSidenav.open();
  }

}
