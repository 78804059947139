import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CustomHeader } from '../models/header.enum';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(
    private readonly translate: TranslateService,
    private readonly notificationService: NotificationService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let skipNotification = false;
    if (request.headers.has(CustomHeader.SkipNotificationHeader)) {
      request = request.clone({ headers: request.headers.delete(CustomHeader.SkipNotificationHeader) });
      skipNotification = true;
    }
    return next.handle(request)
      .pipe(
        filter(event => event instanceof HttpResponse),
        tap(() => {
          if (!skipNotification) {
            switch (request.method) {
              case 'POST': this.notificationService.infoMsg(this.translate.instant(`http.successfullyCreated`)); break;
              case 'PUT': this.notificationService.infoMsg(this.translate.instant(`http.successfullyUpdated`)); break;
              case 'DELETE': this.notificationService.infoMsg(this.translate.instant(`http.successfullyDeleted`)); break;
            }
          }
        }),

        catchError((error: HttpErrorResponse) => {
          if (skipNotification) {
            return throwError(() => error);
          }
          switch (error.status) {
            case 500: { // only for error 500 we wait for the user's confirmation with the OK button
              this.notificationService.errorMsg(this.translate.instant(`http.error`, {
                errorTitle: this.translate.instant(`http.${error.error?.title || error.status}`),
                errorDescription: error.error?.description || error.error?.Message || '',
              }), 0, 'OK');
            }
              break;
            default:
              this.notificationService.errorMsg(this.translate.instant(`http.error`, {
                errorTitle: this.translate.instant(`http.${error.error?.title || error.status}`),
                errorDescription: error.error?.description || error.error?.Message || '',
              }));
          }
          return throwError(() => error);
        }),

      );
  }

}
