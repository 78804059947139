import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { UserClaims } from '@okta/okta-auth-js';
import { Observable, switchMap, tap } from 'rxjs';
import { ToolbarButtons } from '../../models/toolbar-buttons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuButton } from '../../models/menu-button.model';
import { NotificationService } from '../../services/notification.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatCardModule,
    TranslateModule,
  ]
})
export class ToolbarComponent {
  @Output() menuToggleEvent = new EventEmitter();
  @Output() buttonClickEvent = new EventEmitter<MenuButton>();
  public envBadge: string;
  public menuButtons: MenuButton[] = ToolbarButtons;
  public user$!: Observable<UserClaims>;
  public isFullscreen = false;
  public isMobile = false;

  private notificationsCount = 0;
  constructor(
    private readonly authService: AuthService,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    this.user$ = this.authService.getUser();
    this.authService.getEmployeeId().pipe(
      switchMap(employeeId => this.notificationService.getCount(employeeId)),
      tap(cnt => this.notificationsCount = cnt),
    ).subscribe()
    environment.name === 'prd' ? this.envBadge = '' : this.envBadge = environment.name.substring(0, 3).toUpperCase();
    this.menuButtons = ToolbarButtons.map(m => ({ ...m, label: this.translateService.instant(`menu.${m.label}`) }));

    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(
      (result: BreakpointState) => this.isMobile = result.matches
    );
  }


  onMenuClick(): void {
    this.menuToggleEvent.emit();
  }


  onMenuButtonClick(button: MenuButton): void {
    this.buttonClickEvent.emit(button);
  }


  getBadge(ident: string): string {
    if (this.notificationsCount > 0 && ident === 'notification') {
      return this.notificationsCount.toString();
    }
    return '';
  }


  logout(): void {
    this.authService.logout().subscribe();
  }


  onFullScreenClick(): void {
    this.isFullscreen ? this.closeFullscreen() : this.openFullscreen();
  }


  private openFullscreen(): void {
    const el = document.documentElement as HTMLElement;
    el.requestFullscreen();
    this.isFullscreen = true;
  }


  private closeFullscreen(): void {
    const el = document as Document;
    el.exitFullscreen();
    this.isFullscreen = false;
  }


}
