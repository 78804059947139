import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { ToolbarComponent } from './app-core/ui/toolbar/toolbar.component';
import { NotificationService } from './app-core/services/notification.service';

import { ThemeService } from './app-core/services/theme.service';
import { AuthService } from './app-core/services/auth.service';
import { SidenavComponent } from './app-core/ui/sidenav/sidenav.component';
@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    SidenavComponent,
    HttpClientModule,
    ToolbarComponent
  ],
})


export class AppComponent implements OnInit {
  public notificationsCount = 0;
  constructor(
    private readonly notificationService: NotificationService,
    private readonly translate: TranslateService,
    private readonly themeService: ThemeService,
    private readonly authService: AuthService,
  ) { }


  ngOnInit(): void {
    this.authService.isSupervisor().subscribe();
    this.themeService.setDefault();
    this.configureLanguage();
    this.notificationService.checkForUpdates().subscribe(
      sw => console.log(sw.type)
    );
    this.notificationService.requestNotifications().subscribe();
  }


  private configureLanguage(): void {
    this.translate.setDefaultLang('en-US'); // fallback lang
    const lang = localStorage.getItem('language') || 'pl-PL'; // set polish as default 
    this.translate.use(lang)
      .pipe(
        first()
      ).subscribe();
  }

}
