import { ErrorHandler, Injectable } from "@angular/core";
import { Observable, first, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CustomHeader } from "../models/header.enum";
import { AuthService } from "./auth.service";
import { serializeError } from "serialize-error";
import { environment } from "../../../environments/environment";


@Injectable()
export class ErrorService implements ErrorHandler {
  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) { }

  handleError(error: unknown) {
    console.log('<==================== Error from global error handler ====================>');
    console.error(error);
    console.log('<=============================== End =====================================>');
    if (environment.name !== 'local') {
      this.logException(error).subscribe(
        resp => console.log('Error reported successfully', resp),
      );
    }
  }


  logException(error: unknown): Observable<void> {
    return this.authService.getEmployeeEmail().pipe(
      first(),
      switchMap(usr => {
        const ex = [{
          error: serializeError(error),
          user: usr,
          localtime: new Date().toLocaleTimeString('pl-PL'),
          env: environment.name,
          location: window.location,
          url: window.location.href,
          user_agent: window.navigator.userAgent,
        }];
        return this.http.post<void>(`${environment.urls.reportingApi}default`, ex, { headers: { [CustomHeader.SkipNotificationHeader]: '' } })
      })
    )
  }


  log(log: string): Observable<void> {
    return this.http.post<void>(`${environment.urls.reportingApi}default`, log, { headers: { [CustomHeader.SkipNotificationHeader]: '' } })
  }

}