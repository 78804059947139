import { ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { ServiceWorkerModule, provideServiceWorker } from '@angular/service-worker';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { environment } from '../environments/environment';
import { SupervisorGuard } from './app-core/guards/role.guard';
import { AuthService } from './app-core/services/auth.service';
import { ThemeService } from './app-core/services/theme.service';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AuthInterceptor } from './app-core/interceptors/auth.interceptor';
import { ErrorCatchingInterceptor } from './app-core/interceptors/error-catching.interceptor';
import { LoadingInterceptor } from './app-core/interceptors/loading.interceptor';
import { ErrorService } from './app-core/services/error.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import localePl from '@angular/common/locales/pl';

registerLocaleData(localePl);
const oktaAuth = new OktaAuth(environment.okta);
export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes, withViewTransitions()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AuthService,
    ThemeService,
    SupervisorGuard,
    importProvidersFrom([
      HttpClientModule,
      OktaAuthModule,
      MatSnackBarModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: function (http: HttpClient) {
            return new TranslateHttpLoader(http, `/assets/i18n/`);
          },
          deps: [HttpClient]
        }
      })
    ]),
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'yyyy-MM-dd' }
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pl-PL'
    },
    {
      provide: LOCALE_ID, useValue: 'pl-PL'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ]
};
