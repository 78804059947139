import { Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { OktaGroupGuard } from './app-core/guards/okta-group-guard';
import { SupervisorGuard } from './app-core/guards/role.guard';

const title = 'Oceny DL';
export const routes: Routes = [
  {
    path: 'login/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'home',
    loadComponent: () => import('./components/pages/home/home.component').then(m => m.HomeComponent),
    title: `${title} | Start`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'employee-dashboard',
    loadComponent: () => import('./components/pages/employee-dashboard/employee-dashboard.component').then(m => m.EmployeeDashboardComponent),
    title: `${title} | Moja ocena`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'supervisor-dashboard',
    loadComponent: () => import('./components/pages/supervisor-dashboard/supervisor-dashboard.component').then(m => m.SupervisorDashboardComponent),
    title: `${title} | Oceny pracowników`,
    canActivate: [OktaAuthGuard, SupervisorGuard],
  },
  {
    path: 'hr-dashboard',
    loadComponent: () => import('./components/pages/hr-dashboard/hr-dashboard.component').then(m => m.HrDashboardComponent),
    title: `${title} | Dział personalny`,
    canActivate: [OktaAuthGuard, OktaGroupGuard],
    data: {
      expectedGroup: [
        'IAM_DLAssessments_DEV_ADMIN',
        'IAM_DLAssessments_STG_ADMIN',
        'IAM_DLAssessments_PRD_ADMIN',
      ]
    }
  },
  {
    path: 'reader',
    loadComponent: () => import('./components/pages/reader/reader.component').then(m => m.ReaderComponent),
    title: `${title} | Skaner`,
    canActivate: [OktaAuthGuard, OktaGroupGuard],
    data: {
      expectedGroup: [
        'IAM_DLAssessments_DEV_ADMIN',
        'IAM_DLAssessments_STG_ADMIN',
        'IAM_DLAssessments_PRD_ADMIN',
      ]
    }
  },
  {
    path: 'assessment/:id',
    loadComponent: () => import('./components/pages/assessment/assessment.component').then(m => m.AssessmentComponent),
    title: `${title} | Ocena`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'help',
    loadComponent: () => import('./app-core/ui/sidenav/help/help.component').then(m => m.HelpComponent),
    title: `${title} | Pomoc`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'notification',
    loadComponent: () => import('./app-core/ui/sidenav/notification/notification.component').then(m => m.NotificationComponent),
    title: `${title} | Powiadomienia`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'account',
    loadComponent: () => import('./app-core/ui/sidenav/account/account.component').then(m => m.AccountComponent),
    title: `${title} | Konto`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./components/pages/settings/routes').then(m => m.settingsRoutes),
    title: `${title} | Ustawienia`,
    canActivate: [OktaAuthGuard, OktaGroupGuard],
    data: {
      expectedGroup: [
        'IAM_DLAssessments_DEV_ADMIN',
        'IAM_DLAssessments_STG_ADMIN',
        'IAM_DLAssessments_PRD_ADMIN',
      ]
    }
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
];
