import { MenuButton } from "./menu-button.model";

export const ToolbarButtons: MenuButton[] = [
  {
    label: 'help',
    icon: 'help_outline',
    link: 'help'
  },
  {
    label: 'notifications',
    icon: 'notifications',
    link: 'notification'
  },
  {
    label: 'preferences',
    icon: 'manage_accounts',
    link: 'account'
  },
];
