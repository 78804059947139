import { MenuButton } from "./menu-button.model";

export const NavigationItems: MenuButton[] = [
    {
        label: 'menu.employeeDashboard',
        link: 'employee-dashboard',
        icon: 'person_outline',
    },
    {
        label: 'menu.supervisorDashboard',
        link: 'supervisor-dashboard',
        icon: 'move_to_inbox',
    },
    {
        label: 'menu.hrDashboard',
        link: 'hr-dashboard',
        icon: 'admin_panel_settings',
    },
    {
        label: 'menu.scanner',
        link: 'reader',
        icon: 'qr_code_scanner',
    },
];