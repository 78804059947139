<mat-toolbar color="primary">
  <mat-toolbar-row>

    <button mat-icon-button (click)="onMenuClick()" aria-label="Menu toggle"
      matTooltip="{{ 'menu.menuToggle' | translate }}">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="title">
      {{ 'app.title' | translate }}
    </div>

    @if(!isMobile){
    <button mat-icon-button (click)="onFullScreenClick()" aria-label="Fullscreen toggle"
      matTooltip="{{ 'menu.fullScreenToggle' | translate }}">
      <mat-icon>{{isFullscreen ? 'zoom_in_map' : 'zoom_out_map' }}</mat-icon>
    </button>
    }
    <div class="remaining-space"></div>

    @for (button of menuButtons; track button) {
    <button mat-icon-button (click)="onMenuButtonClick(button)" matTooltip="{{ button.label | translate }}">
      <mat-icon [matBadge]="getBadge(button.link)" matBadgeColor="warn" aria-hidden="false">{{button.icon}}</mat-icon>
    </button>
    }

    @if (user$|async; as user) {
    <button class="small-avatar" mat-icon-button [ngStyle]="{'background-color': user['color']}"
      [matMenuTriggerFor]="menu" [matTooltip]="user.name||''">
      {{ user['initials']}}
    </button>
    }

  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menu="matMenu">
  @if (user$|async; as user) {
  <mat-card>
    <mat-card-title-group>
      <div class="circle" [ngStyle]="{'background-color': user['color']}" mat-card-avatar>
        <span class="initials">{{ user['initials'] }}</span>
      </div>
      <mat-card-title>{{ user.name }}</mat-card-title>
      <mat-card-subtitle>{{ user.email | lowercase }}</mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-actions>
      <button mat-raised-button color="warn" (click)="logout()">
        Logout
        <mat-icon>logout</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
  }
</mat-menu>